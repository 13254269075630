/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const cardsSeStyles = makeStyles(() => ({
  cardsSetSec: {
    padding: '100px 0',
  },
  title: {
    marginBottom: '30px!important',
  },
  subtitle: {
    marginBottom: '72px',
  },
  columnTitle: {
    marginBottom: '55px',
  },
  columnTitleTertiary: {
    marginTop: '-6px',
  },
  smallCard: {
    marginBottom: '30px',
  },
  bigCard: {
    marginBottom: '50px',
  },
  footnoteLink: {
    textDecoration: 'underline',
  },
}));
