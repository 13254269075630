// @flow
//Core
import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Container, Grid, Typography } from '@material-ui/core';
import formatDate from 'date-format';
//Local
import typography from '../../../../styles/typography.module.scss';
import { cardArticlesStyles } from './styles';
import Card from '../../../Card/';
import { filterCardForPublish, filterCardsForPublish } from '../../utils';

const CardArticlesListSec = ({
  title,
  card,
  articles,
  footnoteLink,
  hasBackground = false,
  centeredCardBackground,
}: {
  title: string,
  card: Object,
  articles: Array<Object>,
  footnoteLink?: Object,
  hasBackground?: boolean,
  centeredCardBackground: boolean,
}) => {
  const styles = cardArticlesStyles();
  const filteredCard = filterCardForPublish(card);
  const filteredArticles = filterCardsForPublish(articles);

  const articlesJSX = filteredArticles.map((article, index) => (
    <li className={styles.articlesListItem} key={index}>
      <div
        className={classNames(styles.articlesListTag, typography.textSmDark)}
      >
        {article.tags.join(', ')}
      </div>
      <h5
        className={classNames(styles.articlesListTitle, typography.titleH5Dark)}
      >
        <Link to={article.url}>{article.title}</Link>
      </h5>
      <Typography
        variant="subtitle1"
        color="primary"
        className={styles.articlesListDate}
      >
        {formatDate('yyyy/MM/dd', new Date(article.date))}
      </Typography>
    </li>
  ));

  return (
    <section
      className={classNames(styles.cardArticlesListSec, {
        [styles.cardArticlesListSecWithBg]: hasBackground,
      })}
    >
      <Container maxWidth="md">
        <h2
          className={classNames(
            styles.sectionTitle,
            typography.titleH4Dark,
            typography.withDividerLeftPurple
          )}
        >
          {title}
        </h2>

        <Grid container spacing={6}>
          {filteredCard && (
            <Grid item xs={12} sm={6} md={7}>
              <Card
                img={filteredCard && filteredCard.imgUrl}
                centeredBackground={centeredCardBackground}
                tags={filteredCard.tags}
                title={filteredCard.title}
                variant="primary"
                theme="dark"
                size="md"
                link={filteredCard.url}
                date={formatDate('yyyy/MM/dd', new Date(filteredCard.date))}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={5}>
            <ul className={styles.articlesList}>{articlesJSX}</ul>
          </Grid>
        </Grid>

        {footnoteLink && (
          <Link
            to={footnoteLink.url}
            className={classNames(typography.linkMdDark, styles.bottomLink)}
          >
            {footnoteLink.title}
          </Link>
        )}
      </Container>
    </section>
  );
};

CardArticlesListSec.defaultProps = {
  hasBackground: false,
  footnoteLink: null,
  centeredCardBackground: true,
};

export default CardArticlesListSec;
