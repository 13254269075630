/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const cardArticlesStyles = makeStyles(() => ({
  cardArticlesListSec: {
    padding: '65px 0 70px 0',
  },
  cardArticlesListSecWithBg: {
    backgroundColor: '#EBE6E2',
  },
  sectionTitle: {
    marginBottom: '55px',
  },
  bottomLink: {
    marginTop: '30px',
    display: 'inline-block',
  },
  articlesList: {
    listStyle: 'none',
  },
  articlesListDate: {
    marginTop: 15,
  },
  articlesListItem: {
    '&:not(:last-child)': {
      paddingBottom: '32px',
      borderBottom: '1px solid rgb(205, 196, 197)',
      marginBottom: '32px',
    },
  },
  articlesListTag: {
    marginBottom: '24px',
  },
  articlesListTitle: {
    '&>a': {
      color: '#101825',
      transition: '.25s ease',
      '&:hover': {
        opacity: 0.7,
      },
    },
  },
}));
